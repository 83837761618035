@charset "utf-8";

/* dirt syntax highlighting (base16) */
$base00: #231e18;
$base01: #302b25;
$base02: #48413a;
$base03: #9d8b70;
$base04: #b4a490;
$base05: #cabcb1;
$base06: #d7c8bc;
$base07: #e4d4c8;
$base08: #d35c5c;
$base09: #ca7f32;
$base0a: #e0ac16;
$base0b: #b7ba53;
$base0c: #6eb958;
$base0d: #88a4d3;
$base0e: #bb90e2;
$base0f: #b49368;

$code-background-color      : #f1abab;

@import "minimal-mistakes/skins/air"; // skin
@import "minimal-mistakes"; // main partials


#main {
    max-width: 100%;
}

.panel {
    padding: 15px;
    margin-bottom: 20px;
    background-color: $background-color;
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgba(0,0,0,0.05);
    box-shadow: 0 1px 1px rgba(0,0,0,0.05)
}

section.page__content img:not(.emoji) {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.archive__item-teaser {
    max-height: 200px !important;
}


